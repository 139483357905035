@font-face {
    font-family: 'MuseoSans';
    src: url('../fonts/MuseoSans_500_Italic-webfont.eot');
    src: url('../fonts/MuseoSans_500_Italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/MuseoSans_500_Italic-webfont.woff2') format('woff2'), url('../fonts/MuseoSans_500_Italic-webfont.woff') format('woff'), url('../fonts/MuseoSans_500_Italic-webfont.ttf') format('truetype'), url('../fonts/MuseoSans_500_Italic-webfont.svg#museo_sans500_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('../fonts/MuseoSans_500-webfont.eot');
    src: url('../fonts/MuseoSans_500-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/MuseoSans_500-webfont.woff2') format('woff2'), url('../fonts/MuseoSans_500-webfont.woff') format('woff'), url('../fonts/MuseoSans_500-webfont.ttf') format('truetype'), url('../fonts/MuseoSans_500-webfont.svg#museo_sans500') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Frankie';
    src: url('../fonts/Frankie.eot');
    src: url('../fonts/Frankie.eot?#iefix') format('embedded-opentype'), url('../fonts/Frankie.woff2') format('woff2'), url('../fonts/Frankie.woff') format('woff'), url('../fonts/Frankie.ttf') format('truetype');
}

@font-face {
    font-family: 'Blackjack';
    src: url('../fonts/Blackjack.eot');
    src: url('../fonts/Blackjack.eot?#iefix') format('embedded-opentype'), url('../fonts/Blackjack.woff') format('woff'), url('../fonts/Blackjack.ttf') format('truetype'), url('../fonts/Blackjack.svg#black_jackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.fp-scroller {
    overflow: visible !important;
}

.hide-for-mobile {
    display: none;
}

@media screen and (min-width: 1024px) {
    .hide-for-mobile {
        display: block;
    }
    .mobile-only {
        display: none !important;
    }
}

body {
    font-family: 'Barlow-Regular';
    margin: 0;
    /*color: rgba(61, 109, 23, 1);*/
    overflow-x: hidden;
}

h2 {
    line-height: 1;
    margin: 0;
    font-size: 2.5rem;
    font-weight: bold;
}

h2>span {
    line-height: 1;
    margin: 0;
    font-size: 3.5rem;
    font-weight: normal;
}

h3 {
    font-weight: 700;
    line-height: 1;
    color: rgba(61, 109, 23, 1);
    margin: 0;
    font-size: 2rem;
}

h3:last-of-type {
    margin-bottom: -1.5rem;
}

a,
a:active,
a:hover,
a:focus {
    color: rgba(61, 109, 23, 1);
}

.sauer .imprint-text a,
a:active,
a:hover,
a:focus {
    color: rgba(255, 255, 255, 1);
}

.quittig .imprint-text a,
a:active,
a:hover,
a:focus {
    color: rgba(0, 0, 0, 1);
}

.center-align {
    text-align: center;
}

.center-align .table-cell {
    text-align: left;
    display: inline-block;
}

.left-align {
    text-align: left;
}

.right-align .table-cell {
    display: block;
}

.right-align .content {
    float: right;
    text-align: left;
}

.navigation {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: inline-block;
    background: rgba(61, 109, 23, 0.26);
    padding: 10px;
    margin: 0;
}

.navigation>li {
    float: left;
    padding: 0 10px;
    list-style: none;
}

.navigation>li>a {
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(255, 255, 0, 1);
    font-weight: bold;
}

.centered-text {
    display: table-cell;
    vertical-align: middle;
}

.navigation-down {
    position: fixed;
    bottom: 10px;
    left: 50%;
    width: 100px;
    cursor: pointer;
    z-index: 2;
}

.navigation-up {
    position: fixed;
    top: 10px;
    left: 50%;
    width: 100px;
    cursor: pointer;
    z-index: 2;
}


/* NEEDED  */

.oz {
    opacity: 0;
}

.fadein {
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}

.section,
.slide {
    background: no-repeat top center;
    background-size: cover;
    background-position: center center;
}

.table {
    display: table;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

.table-cell-bottom {
    display: table-cell;
    vertical-align: bottom;
}

.singlepage-container {
    position: relative;
}

.content {
    font-size: 1.55rem;
    padding: 55px 55px;
    max-width: 580px;
    position: relative;
}

.bg-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.image-container {
    display: table;
    width: 100%;
}

@media only screen and (min-width: 1024px) {
    .image-container {
        position: relative;
        width: 1024px;
        max-width: 1024px;
        margin: 0 auto;
        display: table;
    }
}

@media only screen and (min-width: 1281px) {
    .image-container {
        width: 1280px;
        max-width: 1280px;
    }
}

@media only screen and (min-width: 1601px) {
    .image-container {
        width: 1600px;
        max-width: 1600px;
    }
}

@media only screen and (max-width: 640px) {
    .centered-text {
        max-width: 290px;
        padding: 55px;
    }
    .content-container {
        /*top: 120px !important;*/
        /*left: 0 !important;*/
        position: relative;
    }
}

@media only screen and (max-width: 580px) {
    /*.start-image {
        top: 10%;
        max-width: 260px;
        margin-left: -130px;
    }*/
    .content-container {
        /*top: 0;
        left: auto;*/
        margin: 0 auto;
        /*width: auto;*/
        /*padding: 75px 0;*/
        background-size: cover;
        background-position: center;
    }
    .ckeditor {
        padding: 15px;
    }
    .content {
        padding: 115px 15px 115px 10px;
        font-size: 1.35rem;
    }
}

@media only screen and (max-width: 360px) {
    .content{
        font-size: 1.25rem;
    }
}


/* START QUITTIG AND SAUER */

.mobile-title {
    font-family: 'Blackjack';
    font-size: 12vw;
    text-align: center;
    font-weight: normal;
    padding-top: 10vw;
}

.only-mobile.footer {
    position: fixed;
    bottom: 0;
    background-color: #fff;
}

.decorated-bottle-right.mobile-only {
    bottom: 0;
    position: fixed;
    width: 100%;
    background-color: #fff;
}

.decorated-bottle-right.mobile-only {
    right: inherit !important;
    left: inherit !important;
    top: inherit !important;
    bottom: 0px !important;
}

.imprint{
    text-align: center;
}

.imprint svg{
    max-width: 500px;
}


/* END QUITTIG AND SAUER */


/* START QUITTIG */

.barlow {
  font-family: "Barlow-Regular", sans-serif;
  font-size: 18px !important;
}

.quittig .mobile-title {
    background-image: url(/assets/images/junge_poller.png);
    background-repeat: no-repeat;
    padding-bottom: 670px;
    background-position: bottom center;
    background-size: 800px;
}


/*.quittig .decorated-bottle-right.mobile-only .refreshing svg {
    position: absolute;
    top: 0;
    width: 100%;
    max-height: 200px;
    z-index: 2;
}*/

.quittig {
    background-color: #80b128;
    font-family: 'Blackjack';
}

.ckeditor-quittig .content {
    font-size: 1.5rem;
    line-height: 1.25;
    padding: 100px 65px;
    text-align: center;

    &.morespace {
      padding: 15px 35px;
      @media screen and (max-width: 1023px) {
        font-size: 12px !important;
      }
    }
}

.ckeditor-quittig:nth-of-type(3) {
    padding-bottom: 300px;
}

.boy-sitting-container {
    width: 100%;
    position: relative;
    z-index: 1;
}

.boy-sitting-container .ckeditor-quittig .content{
    padding-bottom: 0;
}

/*.boy-sitting-container .text-container {
    position: relative;
    padding-bottom: 330px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 110%;
}*/

/*.boy-sitting-container .ckeditor-quittig:after {
    content: url(/assets/images/junge_frei.png);
    width: 100%;
    position: relative;
    left: -40%;
}
*/
.boy-sitting-container img {
    width: 100%;
}

.boy-sitting-container .content {
    font-size: 1.5rem;
    text-align: left;
}

.boy-sitting-container .ckeditor-quittig {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .boy-sitting-container .content {
        font-size: 2.5rem;
        text-align: left;
    }
}

@media screen and (min-width: 1024px) {
    .boy-sitting-container img {
        display: block;
    }
    .boy-sitting-container .ckeditor-quittig:after {
        content: '';
        width: 100%;
        position: relative;
        left: -40%;
    }
    .boy-sitting-container .text-container {
        position: absolute;
        bottom: 90px;
        left: 450px;
        width: 475px;
        background-image: none;
        padding-bottom: 0px;
    }
    .boy-sitting-container .text-container .content {
        text-align: left;
    }
    .boy-sitting-container .ckeditor-quittig {
        margin: 0;
    }
    .boy-sitting-container {
        bottom: 20px;
        width: 100%;
        position: absolute;
        z-index: 1;
    }
    .boy-sitting-container img {
        width: 650px;
    }
    .ckeditor-quittig {
        max-width: 450px;
        width: 33%;
    }
    .quittig .content {
        max-width: 850px;
        padding: 150px 15px;
    }
    .ckeditor-quittig .content {
        font-size: 2.25rem;
        line-height: 1;
        /*text-align: center;*/
        padding: 40px 65px;
        text-align: center;
    }
}

@media screen and (min-width: 1281px) {
    font-size: 2rem;
}

@media screen and (min-width: 1281px) {
    font-size: 2.25rem;
}

.quittig-container {
    font-family: 'Frankie';
    position: relative;
    color: white;
    line-height: 1;
    text-transform: uppercase;
}

.decorated-bottle-right.with-background .quittig-container {
    left: -200px;
}

.section_1 .quittig-container {
    right: 20%;
    top: 75%;
}

.section_1 .quittig-container .title {
    color: #80b128;
}

.section_1 .quittig-container .subline {
    color: orange;
}

.fp-scroller {
    height: 100%;
}

.refreshing {
    height: calc(5vw + 5vh + 4vmin);
}

.quittig-container .title {
    font-size: 4em;
}

.quittig-container .subline {
    font-size: 2em;
}

@media only screen and (min-width: 1281px) {
    .quittig-container .title {
        font-size: 5em;
    }
    .quittig-container .subline {
        font-size: 2.4em;
    }
}

@media only screen and (min-width: 1601px) {
    .quittig-container .title {
        font-size: 6em;
    }
    .quittig-container .subline {
        font-size: 2.9em;
    }
}

.quittig-container .title:after {
    content: "\A";
    white-space: pre;
}

.quittig .decorated .ckeditor-quittig {
    display: inline-block;
    vertical-align: top;
    margin-top: 5%;
}

.quittig .decorated .ckeditor-quittig .content p:first-of-type {
    position: relative;
    padding-top: 3vw;
}

.quittig .decorated .ckeditor-quittig:first-of-type .content p:first-of-type:before {
    content: "\00a0\00a0";
    font-size: 30rem;
    background-image: url(/assets/images/apfel.svg);
    background-size: 30%;
    background-repeat: no-repeat;
    position: absolute;
    top: -390px;
    width: 100%;
    /* margin: 0 auto; */
    background-position: center;
    left: 0;
}

.quittig .decorated .ckeditor-quittig:nth-of-type(2) .content p:first-of-type:before {
    content: "\00a0\00a0";
    font-size: 30rem;
    background-image: url(/assets/images/quitte.svg);
    background-size: 45%;
    background-repeat: no-repeat;
    position: absolute;
    top: -390px;
    width: 100%;
    /* margin: 0 auto; */
    background-position: center;
    left: 0;
}

.quittig .decorated .ckeditor-quittig:last-of-type .content p:last-of-type:before {
    content: "\00a0\00a0";
    font-size: 30rem;
    background-image: url(/assets/images/flaschen.svg);
    background-size: 30%;
    background-repeat: no-repeat;
    position: absolute;
    top: -420px;
    width: 100%;
    /* margin: 0 auto; */
    background-position: center;
    left: 0;
}

@media only screen and (min-width: 1024px) {
    .quittig .decorated .ckeditor-quittig:first-of-type .content p:first-of-type:before {
        background-size: 50%;
        top: -300px;
    }
    .quittig .decorated .ckeditor-quittig:nth-of-type(2) .content p:first-of-type:before {
        background-size: 75%;
        top: -300px;
    }
    .quittig .decorated .ckeditor-quittig:last-of-type .content p:last-of-type:before {
        background-size: 43%;
        top: -300px;
    }
}

.quittig .decorated-bottle-right {
    position: absolute;
    right: 150px;
    bottom: 25px;
}

.decorated-bottle-right.with-background {
    bottom: 0px;
    width: 100%;
    height: 120px;
    right: 0px;
    bottom: 0px;
    text-align: right;
    background-color: rgba(0, 0, 0, 0.35);
}

.quittig .section_1 .decorated-bottle-right {
    bottom: 125px;
}

.quittig .decorated-bottle-right.mobile-only .quittig-container {
    left: inherit;
    top: inherit;
    right: inherit;
    text-align: center;
}

.quittig .decorated-bottle-right.mobile-only .quittig-container .title {
    color: #80b128;
    font-size: 2.5rem;
}

.quittig .decorated-bottle-right.mobile-only .quittig-container .subline {
    color: orange;
    font-size: 1.2rem;
}

.quittig .decorated-bottle-right.mobile-only .quittig-container:after {
    content: "\00a0\00a0";
    height: 265px;
    width: 265px;
    background-image: url(/assets/images/quittigflasche.png);
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 240px;
    bottom: -20px;
    transform: rotate(10deg);
}

.section_1 .decorated-boy-left .quittig-container:before {
    content: "\00a0\00a0";
    height: 800px;
    width: 640px;
    background-image: url(/assets/images/junge_poller.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -600px;
    top: -335%;
}

@media only screen and (min-width: 1281px) {
    .section_1 .decorated-boy-left .quittig-container:before {
        height: 800px;
        width: 800px;
        left: -580px;
        top: -375%;
    }
}

@media only screen and (min-width: 1601px) {
    .section_1 .decorated-boy-left .quittig-container:before {
        height: 1000px;
        width: 1000px;
        left: -720px;
        top: -395%;
    }
}

.decorated-bottle-right .quittig-container:after {
    content: "\00a0\00a0";
    height: 525px;
    width: 525px;
    background-image: url(/assets/images/quittigflasche.png);
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 170px;
    bottom: -70px;
    transform: rotate(10deg);
}

.decorated-bottle-right.with-background .quittig-container:after {
    height: 350px;
    width: 350px;
    left: 1000px;
    bottom: -30px;
}

.section_1 .decorated-bottle-right .quittig-container:after {
    bottom: -120px;
}

@media only screen and (min-width: 1281px) {
    .boy-sitting-container {
        bottom: 30px;
    }
    .boy-sitting-container img {
        width: auto;
    }
    .boy-sitting-container .text-container {
        position: absolute;
        bottom: 90px;
        left: 600px;
        width: 100%;
    }
    .decorated-bottle-right .quittig-container:after {
        left: 220px;
    }
    .decorated-bottle-right.with-background {
        height: 150px;
    }
    .decorated-bottle-right.with-background .quittig-container:after {
        left: 1220px;
    }
}

@media only screen and (min-width: 1601px) {
    .boy-sitting-container {
        bottom: 80px;
    }
    .decorated-bottle-right .quittig-container:after {
        left: 300px;
    }
    .decorated-bottle-right.with-background {
        height: 200px;
    }
    .decorated-bottle-right.with-background .quittig-container:after {
        left: 1530px;
    }
}

.decorated-boy-sitting-left .ckeditor {
    width: calc(28vw + 28vh + 28vmin);
}

.decorated-boy-sitting-left .content {
    font-size: calc(1.25vw + 1.25vh + 1vmin);
    text-align: left;
    margin: 0 0 0 auto;
    position: relative;
}

.decorated-boy-sitting-left .content:before {
    content: "\00a0\00a0";
    font-size: calc(15vw + 15vh + 15vmin);
    background-image: url(/assets/images/junge_frei.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: calc(-19vw + -19vh + -15vmin);
    top: 0vh;
    width: 100%;
}

.quittig .ingredients {
    font-family: "Barlow-Regular", sans-serif;
    color: #eee;
    font-size: 0.8rem;
    position: relative;
    padding-left: 75px;
    /*padding-bottom: 75px;
    padding-top: 25px;*/
    background-color: inherit;
    margin-top: 0;
    padding-bottom: 75px;
    width: 100%;
    background-color: rgba(0,0,0,0.35);
}

@media only screen and (min-width: 1024px) {
    .quittig .ingredients {
        font-family: "Barlow-Regular", sans-serif;
        position: absolute;
        left: 25px;
        bottom: 0;
        max-width: 450px;
        font-size: 1rem;
        padding-left: 0;
        padding-bottom: 0;
        background-color: inherit;
    }
}


/* END QUITTIG */


/* START SAUER */

.decorated-girl-left:first-child:before {
    content: "\00a0\00a0";
    font-size: calc(55vw + 55vh + 45vmin);
    background-image: url(/assets/images/junge_frei.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: calc(-19vw + -19vh + -15vmin);
    top: 0vh;
}

.sauer {
    background-color: #b71934;
    color: white;
    //font-family: 'Blackjack';
    font-family: 'Barlow-Regular';
}

.sauer.mobile-only {
    color: #000;
}

.sauer .mobile-title {
    background-image: url(/assets/images/sauerfrau.png);
    background-repeat: no-repeat;
    padding-bottom: 470px;
    background-position: bottom center;
    background-size: 420px;
    color: #b71934;
}

.mobile-only .sauer-container {
    background-color: #fff;
    position: relative;
    left: inherit;
    bottom: inherit;
    text-align: center;
}

.sauer .fp-tableCell {
    vertical-align: bottom;
}

.sauer .section:last-of-type {
    background-position: bottom;
}

.decorated-bottle-right.mobile-only .title {
    color: #b71934;
}

.decorated-bottle-right.mobile-only .subline {
    color: #8c1432;
}

.sauer-container {
    font-family: 'Frankie';
    position: absolute;
    left: 150px;
    bottom: 15px;
    color: white;
    line-height: 1;
    text-transform: uppercase;
}

.sauer-container:before {
    content: "\00a0\00a0";
    font-size: calc(15vw + 15vh + 5vmin);
    background-image: url(/assets/images/sauerflasche.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: calc(-5vh + -5vw + -4vmin);
    bottom: calc(1vh);
    transform: rotate(-10deg);
}

.mobile-only .sauer-container:before {
    font-size: 15rem;
    left: 10px;
    bottom: 10px;
}

.sauer-container .title {
    font-size: 4rem;
}

.mobile-only .sauer-container .title {
    font-size: 2.5rem;
}

.sauer-container .title:after {
    content: "\A";
    white-space: pre;
}

.sauer-container .subline {
    font-size: 2rem;
}

.mobile-only .sauer-container .subline {
    font-size: 1.2rem;
    padding-left: 73px;
}

.sauer.rhabarber {
    display: table;
    width: 100%;
}

.fake-bg-image-container img {
    position: absolute;
    bottom: 80px;
    width: 200%;
    z-index: 0;
    margin-left: -50%;
}

.fake-bg-image-container .content{
    z-index: 1;
}

/*.fake-bg-image-container {
    padding-bottom: 100px;
}*/

.right-text-container {
    position: relative;
    padding: 0 15px 0 25px;
}

/* SPECIAL CASE */
@media screen and (orientation: landscape) and (max-width: 1023px){
    .fake-bg-image-container img {
        width: 100%;
        margin-left: inherit;
    }
}

@media screen and (orientation: landscape) and (max-width: 767px){
    .fake-bg-image-container img {
        position: inherit;
    }

    .ingredients{
        position: inherit;
    }
}

@media screen and (min-width: 1024px){
    .fake-bg-image-container {
        padding-bottom: 0;
    }
    .right-text-container {
        max-width: 350px;
        position: absolute;
        right: 15px;
        bottom: -180px;
        background-image: none;
        z-index: 1;
        padding: 25px 25px 380px 25px;
    }
    
    .fake-bg-image-container img {
        bottom: 0;
        width: 100%;
        margin-left: inherit;
    }
}

.right-text-container .content {
    font-size: 5.5vw;
}

@media screen and (min-width: 768px) {
    .right-text-container .content {
        font-size: 1.5rem;
    }
}

.ingredients {
    font-family: "Barlow-Regular", sans-serif;
    color: #000;
    font-size: 0.55rem;
    margin-top: 7.6rem;
    position: absolute;
    bottom: 30px;
    padding-left: 100px;
    background-color: rgba(195,201,197,1);
    padding-bottom: 40px;
    width:100%;
}

@media only screen and (min-width: 1024px) {
    .ingredients {
        font-family: "Barlow-Regular", sans-serif;
        right: 0;
        bottom: 0;
        max-width: 450px;
        color: #000;
        font-size: 0.8rem;
        padding-left: 0;
        margin-top: 0;
        background-color: none;
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 1281px) {
    .right-text-container {
        max-width: 425px;
        position: absolute;
        right: 50px;
        bottom: -90px;
    }
    .ingredients {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 1601px) {
    .right-text-container {
        right: 140px;
        bottom: 370px;
    }
    .ingredients {
        font-size: 1.3rem;
        right: 100px;
    }
}

.sauer.rhabarber .center-align {
    display: table-cell;
    vertical-align: middle;
}

.sauer .ckeditor .content {
    font-size: 2.2rem;
    line-height: 1.1;
}

.sauer-images-container {
    width: 100%;
    text-align: left;
    position: absolute;
    bottom: 0;
}

.sauer-bottle {
    transform: rotate(-10deg);
}

.sauer-images-container {
    bottom: 50px;
    text-align: center;
}

.sauer-images-container img {
    height: 500px;
    width: auto;
}

@media screen and (min-width: 1281px) {
    .sauer-images-container {
        bottom: 50px;
        text-align: center;
    }
    .sauer-images-container img {
        height: 600px;
        width: auto;
    }
}

@media screen and (min-width: 1601px) {
    .sauer-images-container {
        bottom: 50px;
        text-align: center;
    }
    .sauer-images-container img {
        height: 750px;
        width: auto;
    }
}

.sauer-mark-designation-container {
    text-align: right;
    position: absolute;
    bottom: 0;
    width: 100%;
    float: right;
}

.sauer-mark-designation-color {
    font-size: 3rem;
    font-family: Frankie;
    color: #b71934;
    text-transform: uppercase;
    line-height: 0.8;
    margin: 1rem;
    text-align: left;
    display: inline-block;
}

.sauer-mark-designation-color .larger {
    font-size: 5rem;
}

.sauer-mark-designation-color .darker {
    color: #8c1432;
}

.sauer-mark-designation-color .dark {
    color: #5a0e1c;
}

.sauer-mark-designation-color sup {
    font-size: 30%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -1.6em;
    left: -0.35em;
    font-weight: normal;
}

.ckeditor-sauer .content {
    max-width: 100%;
    padding: 0;
}

.rhabarber .ckeditor-sauer .content {
    max-width: 450px;
    text-align: left;
    display: inline-block;
    padding: 100px;
}

@media screen and (min-width: 1024px) {
    .rhabarber .ckeditor-sauer .content {
        max-width: 450px;
        text-align: left;
        display: inline-block;
        padding: 0;
    }
}

.bottom-align {
    position: absolute;
    bottom: 60px;
    width: 100%;
    line-height: 1;
}

@media screen and (min-width: 1024px) {
    .bottom-align{
        bottom: 0;
    }
}

/* SPECIAL CASE */
@media screen and (max-width: 420px) {
    .bottom-align{
        bottom: inherit;
        top: 0;
        color: #b71934;
    }
}

.bottom-align p {
    font-size: 5vw;
}

.bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.imprint-text {
    text-align: center;
    font-size: 2rem;
    padding-bottom: 4rem;
}

@media screen and (min-width: 1024px) {
    .content:first-of-type {
      .rhabarber-image:before {
        //content: url(/assets/images/rhabarber.png);
        position: absolute;
        left: -220px;
        top: -180px;
      }
      .rhabarber-hero {
        display: none;
      }
    }
}

@media screen and (max-width: 1023px) {
    .content {
      &:first-of-type {
        .rhabarber-image {
          font-size: 0.8rem;
          &:before {
            //background-image: url(/assets/images/rhabarber.png);
            //background-size: 180px 119px;
            //background-size: 140px;
            //display: inline-block;
            //width: 180px;
            //height: 120px;
            content: "";
            //position: absolute;
            //left: 0px;
            //top: 10px;
            //background-repeat: no-repeat;
            display: none;
          }
        }
        .rhabarber-hero {
          display: block;
          margin-left: -50px;
          img {
            width: 60vw;
          }
        }
      }
    .rhabarber-image {
      width: 50vw;
    }
  }
  .imprint-text {
    font-size: 0.8rem !important;
    padding: 1rem;
  }
}


/* END SAUER */
